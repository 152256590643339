import { Link } from "react-router-dom";
import MemeGeneratorImage from "../images/Meme-Generator.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function MemeGenerator() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | Meme Generator</title>
          <meta
            name="description"
            content="JeFf Titak developed this meme generator as a project for the Scrimba React Developer course. In this project, APIs were fetched and text was entered into the random meme."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, meme generator"
          />
        </Helmet>
        <div className="projects-body">
          <p className="projects-paragraph">
            This is a meme generator that helped me understand some of the basic
            fundamental aspects of React.js. I fetched APIs and used a callback
            function with Math.random to generate a random meme. In addition to
            the callback method to generate a random meme, another callback
            function was written to have to text field entered into the image
            below.
          </p>
          <img className="projects-image" src={MemeGeneratorImage} alt="meme" />
        </div>
        <div className="projects-links">
          <h3>
            <Link
              className="project-link"
              to="https://github.com/jtitak06/meme-generator"
            >
              GitHub Repository
            </Link>
          </h3>
          <h3>
            <Link
              className="project-link"
              to="https://jtitak06.github.io/meme-generator/"
            >
              GitHub Pages
            </Link>
          </h3>
        </div>
      </HelmetProvider>
    </>
  );
}
