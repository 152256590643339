const PathConstants = {
    HOME: "/",
    ABOUT: "/about",
    BEERCADE: "/beercade",
    CONTACT: "/contact",
    MEDNOW: "/mednow",
    MEME: "/meme-generator",
    MUSIC: "/music-player",
    NUMBER: "/number-game",
    PETFIND: "/petfind",
    PORTFOLIO: "/portfolio",
    SKILLTEST: "/skill-test",
    SPEECH: "/speech-recognition",
    SPORTS: "/sports-data",
    TICTACTOE: "/tic-tac-toe"
}

export default PathConstants