import { Link } from "react-router-dom";
import PetFindImage from "../images/PetFind-Homepage.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function PetFind() {
  return (
    <>
    <HelmetProvider>
    <Helmet>
          <title>Jeff Titak | PetFind</title>
          <meta
            name="description"
            content="Jeff Titak developed this pet adoption design in Figma with a case study to help guide the design process."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, pet finder"
          />
        </Helmet>
      <div className="projects-body">
        <p className="projects-paragraph">
        PetFind is a mobile and desktop website that helps people find pets to
        adopt. The functionality of the website is designed where someone can
        choose a pet and then pick up the pet at the animal shelter.
        </p>
        <img
          className="projects-image"
          src={PetFindImage}
          alt="pet-find"
        />
      </div>
      <div className="projects-links">
        <h3>
          <Link
            className="project-link"
            to="https://drive.google.com/file/d/1gC55er2JqsntgqYgLnSOAe8oi8tk4S9L/view?usp=sharing"
          >
            Case Study
          </Link>
        </h3>
        <h3>
          <Link
            className="project-link"
            to="https://www.figma.com/proto/jbjAQViQliEOGx6v6AHFs3/Pet-Adoption-Flow?scaling=scale-down&page-id=0%3A1&starting-point-node-id=2%3A3&show-proto-sidebar=1&node-id=8%3A12"
          >
            Prototype
          </Link>
        </h3>
      </div>
      </HelmetProvider>
    </>
  );
}