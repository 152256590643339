import { Link } from "react-router-dom";
import SkillTestImage from "../images/Skill-Test-First-Page.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function SkillTest() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | Skill Test</title>
          <meta
            name="description"
            content="Jeff Titak developed this from a PSD file during an interview as a test to showcase skill."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, skill test"
          />
        </Helmet>
        <div className="projects-body">
          <p className="projects-paragraph">
            This was a skill test developed during a job interview. The
            assignment was to take a PSD file and replicate the design. The CSS
            and JavaScript for this project were all written in the HTML file
            per the request of the interviewer. A mobile and desktop version was
            created as well as functional components coded with JavaScript.
          </p>
          <img
            className="projects-image"
            src={SkillTestImage}
            alt="Skill-Test"
          />
        </div>
        <div className="projects-links">
          <h3>
            <Link
              className="project-link"
              to="https://github.com/jtitak06/Skill_test"
            >
              GitHub Repository
            </Link>
          </h3>
          <h3>
            <Link
              className="project-link"
              to="https://jtitak06.github.io/Skill_test"
            >
              GitHub Pages
            </Link>
          </h3>
        </div>
      </HelmetProvider>
    </>
  );
}
