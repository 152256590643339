import { Link } from "react-router-dom";
import SportsDataImage from "../images/Sports-Data-Screenshot.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function SportsData() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | Sports Data</title>
          <meta
            name="description"
            content="Jeff Titak created a sports data application website for a final project of TrueCoders Boot Camp."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, sports data"
          />
        </Helmet>
        <div className="projects-body">
          <p className="projects-paragraph">
            This is my final project for TrueCoders boot camp. In this project,
            I created the APIs which were uploaded to Heroku. After the APIs
            were uploaded to Heroku, I fetched the APIs in the JavaScript file.
            The data displays on a separate webpage organized by the sport. For
            the desktop version, I created a video background for this project.
          </p>
          <img
            className="projects-image"
            src={SportsDataImage}
            alt="sports-data"
          />
        </div>
        <div className="projects-links">
          <h3>
            <Link
              className="project-link"
              to="https://github.com/jtitak06/final-project-sports-data"
            >
              GitHub Repository
            </Link>
          </h3>
          <h3>
            <Link
              className="project-link"
              to="https://jtitak06.github.io/final-project-sports-data/"
            >
              GitHub Pages
            </Link>
          </h3>
        </div>
      </HelmetProvider>
    </>
  );
}
