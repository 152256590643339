import { Link } from "react-router-dom";
import DesktopPortrait from "../images/Myself-1-Crop.avif";
import MobilePortrait from "../images/Myself-Mobile-Crop.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PathConstants from "../routes/PathConstants";

export default function Home() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | Home</title>
          <meta name="description" content="Jeff Titak is a web developer with design experience who specializes in React." />
          <meta name="keywords" content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer" />
        </Helmet>
        <div className="home-body">
          <h1 className="title">
            <span className="jeff-titak">Jeff Titak</span> is a
            <span className="designer"> web developer</span> with
            <span className="developer"> design experience</span> who
            <span className="react"> specializes in React</span>.
          </h1>
          <img
            className="portrait-desktop"
            src={DesktopPortrait}
            alt="Jeff-Titak"
          />
          <img
            className="portrait-mobile"
            src={MobilePortrait}
            alt="Jeff-Titak"
          />
        </div>
        <button type="button" className="home-button">
          <Link className="home-button_text" to={PathConstants.PORTFOLIO}>
            View My Work
          </Link>
        </button>
      </HelmetProvider>
    </>
  );
}
