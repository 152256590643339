import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

export default function PageLayout() {
    return(
        <>
            <Navbar />
            <Suspense>
                <Outlet />
            </Suspense>
        </>
    )
}