import { Link } from "react-router-dom";
import NumberGameImage from "../images/number-game.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function NumberGame() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | Number Game</title>
          <meta
            name="description"
            content="Jeff Titak developed this number game with JavaScript. The game is won once the correct number is chosen from 1-100."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, number game"
          />
        </Helmet>
        <div className="projects-body">
          <p className="projects-paragraph">
            This is a number game created with JavaScript. The purpose of this
            game is to guess the correct number randomly generated between
            1-100. To add an element of fun, once the correct number is guessed,
            confetti comes down on the screen. There is also a reset button to
            start the game over again once completed.
          </p>
          <img
            className="projects-image"
            src={NumberGameImage}
            alt="number-game"
          />
        </div>
        <div className="projects-links">
          <h3>
            <Link
              className="project-link"
              to="https://github.com/jtitak06/Number_Game"
            >
              GitHub Repository
            </Link>
          </h3>
          <h3>
            <Link
              className="project-link"
              to="https://jtitak06.github.io/Number_Game/"
            >
              GitHub Pages
            </Link>
          </h3>
        </div>
      </HelmetProvider>
    </>
  );
}
