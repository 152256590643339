import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SkillTest from "../images/Skill-Test-First-Page.avif";
import TicTacToe from "../images/Tic-Tac-Toe.avif";
import SportsData from "../images/Sports-Data-Screenshot.avif";
import MusicPlayer from "../images/Music-Player-Screenshot.avif";
import NumberGame from "../images/number-game.avif";
import SpeechRecognition from "../images/speech-recognition.avif";
import PetFind from "../images/PetFind-Homepage.avif";
import Beercade from "../images/Beer-Arcade.avif";
import MedNow from "../images/MedNow.avif";
import PathConstants from "../routes/PathConstants";

export default function Portfolio() {
  return (
    <>
    <HelmetProvider>
        <Helmet>
            <title>Jeff Titak | Portfolio</title>
            <meta name="description" content="Jeff Titak is a web developer with extensive portfolio of projects." />
            <meta name="keywords" content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer" />
        </Helmet>
      <h2 className="portfolio-title">Developed Applications</h2>
      <ul className="grid">
        <li className="grid-item">
          <img className="grid-image" src={SkillTest} alt="skills-test" />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.SKILLTEST}>
              <h3 className="overlay_title">Skill Test</h3>
            </Link>
          </div>
        </li>
        <li className="grid-item">
          <img className="grid-image" src={TicTacToe} alt="tic-tac-toe" />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.TICTACTOE}>
              <h3 className="overlay_title">Tic-Tac-Toe</h3>
            </Link>
          </div>
        </li>
        <li className="grid-item">
          <img className="grid-image" src={SportsData} alt="sports-data" />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.SPORTS}>
              <h3 className="overlay_title">Sports Data</h3>
            </Link>
          </div>
        </li>
        <li className="grid-item">
          <img className="grid-image" src={MusicPlayer} alt="music-player" />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.MUSIC}>
              <h3 className="overlay_title">Music Player</h3>
            </Link>
          </div>
        </li>
        <li className="grid-item">
          <img className="grid-image" src={NumberGame} alt="number-game" />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.NUMBER}>
              <h3 className="overlay_title">Number Game</h3>
            </Link>
          </div>
        </li>
        <li className="grid-item">
          <img
            className="grid-image"
            src={SpeechRecognition}
            alt="speech-recognition"
          />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.SPEECH}>
              <h3 className="overlay_title">Speech Recognition</h3>
            </Link>
          </div>
        </li>
      </ul>
      <h2 className="portfolio-title">Application Designs</h2>
      <ul className="grid">
        <li className="grid-item">
          <img className="grid-image" src={PetFind} alt="pet-find" />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.PETFIND}>
              <h3 className="overlay_title">Pet Adoption</h3>
            </Link>
          </div>
        </li>
        <li className="grid-item">
          <img className="grid-image" src={Beercade} alt="beercade" />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.BEERCADE}>
              <h3 className="overlay_title">Beer Arcade</h3>
            </Link>
          </div>
        </li>
        <li className="grid-item">
          <img className="grid-image" src={MedNow} alt="medical" />
          <div className="overlay">
            <Link className="overlay_link" to={PathConstants.MEDNOW}>
              <h3 className="overlay_title">Medical Assistance</h3>
            </Link>
          </div>
        </li>
      </ul>
      </HelmetProvider>
    </>
  );
}
