import { Link } from "react-router-dom";
import MusicPlayerImage from "../images/Music-Player-Screenshot.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function MusicPlayer() {
  return (
    <>
    <HelmetProvider>
    <Helmet>
          <title>Jeff Titak | Music Player</title>
          <meta
            name="description"
            content="Jeff Titak developed this music player in React with multiple hooks to create the functionality."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, music player"
          />
        </Helmet>
      <div className="projects-body">
        <p className="projects-paragraph">
        This is a music player created with React. To create this application, I
        used three different hooks. In addition to the hooks, multiple
        components were created and exported to the app.js file. The songs for
        the music player app were popular afrobeats songs at the time of
        creation.
        </p>
        <img
          className="projects-image"
          src={MusicPlayerImage}
          alt="music-player"
        />
      </div>
      <div className="projects-links">
        <h3>
          <Link
            className="project-link"
            to="https://github.com/jtitak06/music-player-app"
          >
            GitHub Repository
          </Link>
        </h3>
        <h3>
          <Link
            className="project-link"
            to="https://jtitak06.github.io/music-player-app/"
          >
            GitHub Pages
          </Link>
        </h3>
      </div>
      </HelmetProvider>
    </>
  );
}