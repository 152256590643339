import { Link } from "react-router-dom";
import TicTacToeImage from "../images/Tic-Tac-Toe.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function TicTacToe() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | Tic-Tac-Toe</title>
          <meta
            name="description"
            content="Jeff Titak developed this game of tic-tac-toe with JavaScript."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, tic-tac-toe"
          />
        </Helmet>
        <div className="projects-body">
          <p className="projects-paragraph">
            This is a tic-tac-toe game designed to be enjoyed by two users. The
            design is simple with the main priority on functionality. The
            functional components were coded in JavaScript. If there is a
            winner, the game characters turn to green to signal a winner.
          </p>
          <img
            className="projects-image"
            src={TicTacToeImage}
            alt="Tic-Tac-Toe"
          />
        </div>
        <div className="projects-links">
          <h3>
            <Link
              className="project-link"
              to="https://github.com/jtitak06/tic-tac-toe"
            >
              GitHub Repository
            </Link>
          </h3>
          <h3>
            <Link
              className="project-link"
              to="https://jtitak06.github.io/tic-tac-toe/"
            >
              GitHub Pages
            </Link>
          </h3>
        </div>
      </HelmetProvider>
    </>
  );
}
