import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import DesktopPortrait from "../images/Myself-1-Crop.avif";
import MobilePortrait from "../images/Myself-Mobile-Crop.avif";

export default function Contact() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | Contact</title>
          <meta
            name="description"
            content="Jeff Titak is a highly motivated web developer. Contact him to here to help with your project."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, contact"
          />
        </Helmet>
        <div className="contact-upper-wrapper">
          <div className="contact-text-wrapper">
            <h2 className="contact-title">
              Are you ready to hire your next developer?
            </h2>
            <img
              className="portrait-mobile"
              src={MobilePortrait}
              alt="Jeff-Titak"
            />
            <p className="contact-description">
              Contact me today and let's get started developing your next
              application.
            </p>
          </div>
          <img
            className="portrait-desktop"
            src={DesktopPortrait}
            alt="Jeff-Titak"
          />
        </div>
        <div className="contact-lower-wrapper">
          <p className="contact-info"><span className="contact-icon">✉:</span> jtitak06@yahoo.com</p>
          <p className="contact-info"><span className="contact-icon">☏:</span> +1-219-718-5645</p>
          <Link className="contact-info" to="https://github.com/jtitak06">
            Follow me on Github
          </Link>
        </div>
      </HelmetProvider>
    </>
  );
}
