import { Link } from "react-router-dom";
import SpeechRecognitionImage from "../images/speech-recognition.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function SpeechRecognition() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | Speech Recognition</title>
          <meta
            name="description"
            content="Jeff Titak developed this speech recognition application with JavaScript. Words appear on the screen once you speak into the computer's microphone."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, speech recognition"
          />
        </Helmet>
        <div className="projects-body">
          <p className="projects-paragraph">
            This is a speech recognition app created with JavaScript. The code
            in JavaScript is written so the user can start and stop the app by
            the click of a button. The words display on the screen when spoken.
          </p>
          <img
            className="projects-image"
            src={SpeechRecognitionImage}
            alt="speech-recognition"
          />
        </div>
        <div className="projects-links">
          <h3>
            <Link
              className="project-link"
              to="https://github.com/jtitak06/speech-recognition"
            >
              GitHub Repository
            </Link>
          </h3>
          <h3>
            <Link
              className="project-link"
              to="https://jtitak06.github.io/speech-recognition/"
            >
              GitHub Pages
            </Link>
          </h3>
        </div>
      </HelmetProvider>
    </>
  );
}
