import { Link } from "react-router-dom";
import BeercadeImage from "../images/Beer-Arcade.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Beercade() {
  return (
    <>
    <HelmetProvider>
    <Helmet>
          <title>Jeff Titak | Beercade</title>
          <meta
            name="description"
            content="Jeff Titak developed this beercade design in Figma with a case study to help guide the design process."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, beer arcade"
          />
        </Helmet>
      <div className="projects-body">
        <p className="projects-paragraph">
        Play It Forward is a fictional beer arcade. The design incorporates many
        feaures such as a game preview app, a scheduling app, and a menu to view
        the craft beer selections.
        </p>
        <img
          className="projects-image"
          src={BeercadeImage}
          alt="beercade"
        />
      </div>
      <div className="projects-links">
        <h3>
          <Link
            className="project-link"
            to="https://drive.google.com/file/d/197JVQruCIxrOV3PlwpY5m0D33TXsoLCT/view?usp=sharing"
          >
            Case Study
          </Link>
        </h3>
        <h3>
          <Link
            className="project-link"
            to="https://www.figma.com/proto/lt9gYKjeGrVbRZcYSIDAj7/Project-Wireframes?scaling=scale-down&page-id=0%3A1&starting-point-node-id=26%3A28&node-id=26%3A28"
          >
            Prototype
          </Link>
        </h3>
      </div>
      </HelmetProvider>
    </>
  );
}