import AboutPortrait from "../images/Portrait-myself.avif";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PathConstants from "../routes/PathConstants";

export default function About() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Jeff Titak | About</title>
          <meta
            name="description"
            content="Jeff Titak is a web developer with design experience who specializes in React. Learn more about him here."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer"
          />
        </Helmet>
        <div className="about">
          <h2 className="about-title">
            <span className="wow">Wow!</span> A whole page just about me.
          </h2>
          <div className="about-body">
            <div className="about-paragraphs-wrapper">
              <p className="about-paragraph">
                My name is Jeff Titak. My strong work ethic and dedication for
                continuous growth helps drive my passion for creating beautiful,
                usable, and professional websites and applications with the
                latest standards.
              </p>
              <br />
              <p className="about-paragraph">
                I have a working proficiency in HTML, CSS, and Javascript. All
                of my designs are created in Figma. A humble brag, but my
                creations are pretty amazing. Don't believe me?&nbsp;
                <Link className="paragraph-link" to={PathConstants.PORTFOLIO}>
                  See for yourself.
                </Link>
              </p>
            </div>
            <img className="about-portrait" src={AboutPortrait} alt="myself" />
            <div className="characteristics">
              <h3 className="characteristics-title">Certifications</h3>
              <Link
                className="characteristics-link"
                to="https://app.digit.ink/view-credential/17072009-c5f4-4495-b3af-dfaa04fcc13a"
                target="_blank"
              >
                TrueCoders Boot Camp
              </Link>
              <Link
                className="characteristics-link"
                to="https://coursera.org/share/ae4d2c906de7818ba315bfd69fec1993"
                target="_blank"
              >
                Google UX Design
              </Link>
              <h3 className="characteristics-title">Skills</h3>
              <p className="characteristics-body">Creative</p>
              <p className="characteristics-body">Critical Thinking</p>
              <p className="characteristics-body">Problem Solving</p>
              <p className="characteristics-body">Team Player</p>
              <p className="characteristics-body">Strong Communicator</p>
              <h3 className="resume">
                <Link
                  className="resume-link"
                  to="https://drive.google.com/file/d/1xgQQWbTW5Umos1ellpcbp43Q6mqgbZiJ/view?usp=sharing"
                >
                  Resume
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
}
