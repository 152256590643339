import PathConstants from "./PathConstants";
import Home from "../pages/Home";
import About from "../pages/About";
import Beercade from "../pages/Beercade";
import Contact from "../pages/Contact";
import MedNow from "../pages/MedNow";
import MemeGenerator from "../pages/MemeGenerator";
import MusicPlayer from "../pages/MusicPlayer";
import NumberGame from "../pages/NumberGame";
import PetFind from "../pages/PetFind";
import Portfolio from "../pages/Portfolio";
import SkillTest from "../pages/SkillTest";
import SpeechRecognition from "../pages/SpeechRecognition";
import SportsData from "../pages/SportsData";
import TicTacToe from "../pages/TicTacToe";

export const paths = [
    {
        path: PathConstants.HOME,
        element: (
            <Home />
        )
    },
    {
        path: PathConstants.ABOUT,
        element: (
            <About />
        )
    },
    {
        path: PathConstants.BEERCADE,
        element: (
            <Beercade />
        )
    },
    {
        path: PathConstants.CONTACT,
        element: (
            <Contact />
        )
    },
    {
        path: PathConstants.MEDNOW,
        element: (
            <MedNow />
        )
    },
    {
        path: PathConstants.MEME,
        element: (
            <MemeGenerator />
        )
    },
    {
        path: PathConstants.MUSIC,
        element: (
            <MusicPlayer />
        )
    },    {
        path: PathConstants.NUMBER,
        element: (
            <NumberGame />
        )
    },
    {
        path: PathConstants.PETFIND,
        element: (
            <PetFind />
        )
    },
    {
        path: PathConstants.PORTFOLIO,
        element: (
            <Portfolio />
        )
    },
    {
        path: PathConstants.SKILLTEST,
        element: (
            <SkillTest />
        )
    },
    {
        path: PathConstants.SPEECH,
        element: (
            <SpeechRecognition />
        )
    },
    {
        path: PathConstants.SPORTS,
        element: (
            <SportsData />
        )
    },
    {
        path: PathConstants.TICTACTOE,
        element: (
            <TicTacToe />
        )
    },
]