import { NavLink } from "react-router-dom";
import { useState } from "react";

export default function Navbar() {
    const [mobileDisplay, setMobileDisplay] = useState(false);

    const isClicked = () => {
      setMobileDisplay(!mobileDisplay);
    };

    return (
        <>
        <nav>
          <div className="desktop-menu">
            <h2 className="header-jt">Jeff Titak</h2>
            <ul className="header-tabs">
              <li>
                <NavLink className="tab" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="tab" to="/about">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink className="tab" to="/portfolio">
                  Portfolio
                </NavLink>
              </li>
              <li>
                <NavLink className="tab" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="mobile-menu">
            <h2 className="header-jt">Jeff Titak</h2>
            <button
              onClick={isClicked}
              style={
                mobileDisplay
                  ? { visibility: "hidden" }
                  : { visibility: "visible" }
              }
              className="menu-btn"
            >
              ☰
            </button>
            <div
              className={`${
                mobileDisplay ? "mobile-overlay active" : "mobile-overlay"
              }`}
            >
              <button className="close-btn" onClick={isClicked}>
                ✖
              </button>
              <ul className="mobile-container">
                <li className="mobile-link">
                  <NavLink className="mobile-tab" to="/" onClick={isClicked}>
                    Home
                  </NavLink>
                </li>
                <li className="mobile-link">
                  <NavLink className="mobile-tab" to="/about" onClick={isClicked}>
                    About
                  </NavLink>
                </li>
                <li className="mobile-link">
                  <NavLink className="mobile-tab" to="/portfolio" onClick={isClicked}>
                    Portfolio
                  </NavLink>
                </li>
                <li className="mobile-link">
                  <NavLink className="mobile-tab" to="/contact" onClick={isClicked}>
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        </>
    )
}