import { Link } from "react-router-dom";
import MedNowImage from "../images/MedNow.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function MedNow() {
  return (
    <>
    <HelmetProvider>
    <Helmet>
          <title>Jeff Titak | MedNow</title>
          <meta
            name="description"
            content="Jeff Titak developed this medical assistance design in Figma with a case study to help guide the design process."
          />
          <meta
            name="keywords"
            content="Jeff Titak, Titak, junior developer, HTML, CSS, portfolio, UX designer, web developer, react developer, front-end developer, medical assistance"
          />
        </Helmet>
      <div className="projects-body">
        <p className="projects-paragraph">
        MedNow is designed to help people with no income attain health
        insurance. There is also a fundraiser section to help people with
        overwhelming medical debt.
        </p>
        <img
          className="projects-image"
          src={MedNowImage}
          alt="med-now"
        />
      </div>
      <div className="projects-links">
        <h3>
          <Link
            className="project-link"
            to="https://drive.google.com/file/d/1-CtaP0h2j7B0PqYI4RjnCZPhmoMRxNl6/view?usp=sharing"
          >
            Case Study
          </Link>
        </h3>
        <h3>
          <Link
            className="project-link"
            to="https://www.figma.com/proto/2uvReOaOp443DDJJGDPAXL/MedNow?node-id=78%3A2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=78%3A2&show-proto-sidebar=1"
          >
            Prototype
          </Link>
        </h3>
      </div>
      </HelmetProvider>
    </>
  );
}