import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PageLayout from "./components/PageLayout";
import { paths } from "./routes";
import "./styles.css";

export default function App() {

  const router = createBrowserRouter([
    {
      element: <PageLayout />,
      children: paths
    }
  ])
  
  return (
    <RouterProvider router={router} />
  );
}
